import emailjs from 'emailjs-com';

export default {
  name: "Homepage",
  data() {
    return {
      document: null,
      pagePromise: null,
      form: {name: null, email: null, phone: null, message: null, website: null},
      showError: false,
      showSuccess: false,
      sending: false,
      brandLogoParamString: '',
    };
  },
  methods: {
    getContent() {
      this.$root.$emit("loading");

      this.pagePromise = this.$prismic.client.getSingle("homepage").then((document) => {
        if (document) {
          this.document = document;
          this.$root.$emit("loaded");
        }
      });
    },
    loadBackgroundImage(element, url) {
      if (url) {
        var image = new Image();
        image.onload = () => {
          element.classList.add("loaded");
        }
        image.src = url;
        element.style.backgroundImage = `url('${image.src}')`;
      }
    },
    fadeInBackgroundVideo() {
      const element = document.querySelector('.hero__video');
      const overlay = document.querySelector('.hero__video-overlay');
      element.onloadeddata = () => {
        overlay.classList.add("loaded");
      };
    },
    loadBackgroundMedia() {
      this.pagePromise.then(() => {
        this.fadeInBackgroundVideo();
        
        const bgImgSections = document.querySelectorAll('[data-image-url]');

        bgImgSections.forEach(element => {
          const bgImgUrl = element.getAttribute("data-image-url");
          if (bgImgUrl) {
            this.loadBackgroundImage(element, bgImgUrl);
          }
        });
      });
    },
    next() {
      const slider = this.$refs.sliderContainer;

      if (slider.scrollLeft >= slider.scrollWidth - slider.clientWidth - 10) {
        slider.scrollLeft = 0;
      } else {
        slider.scrollLeft += slider.clientWidth;
      }
    },
    prev() {
      const slider = this.$refs.sliderContainer;

      if (slider.scrollLeft <= slider.clientWidth) {
        slider.scrollLeft = slider.scrollWidth;
      } else {
        slider.scrollLeft -= slider.clientWidth;
      }
    },
    sendEnquiry() {
      this.sending = true;
      this.showError = false;

      if (!this.form.website) {
        emailjs.sendForm('service_w0jwvss', 'contact_form', '#contact-form')
          .then((result) => {
            this.showSuccess = true;
            console.log('Success', result.status, result.text);
          }, (error) => {
            this.showError = true;
            this.sending = false;
            console.log('Failed', error)
          });
      }
    },
    scrollToRandomTestimonial() {
      this.pagePromise.then(() => {
        const slider = this.$refs.sliderContainer;
        slider.scrollLeft = Math.random() * slider.scrollWidth;
      });
    },
    setBrandLogoParamString() {
      if (window.innerWidth > 800) {
        this.brandLogoParamString = `&w=168`;
      } else {
        this.brandLogoParamString = `&h=96`;
      }
    }
  },
  created() {
    this.setBrandLogoParamString();
    this.getContent();
    this.loadBackgroundMedia();
    this.scrollToRandomTestimonial();
  }
};
