import Vue from "vue";
import App from "./App.vue";
import PrismicVue from "@prismicio/vue";
import router from "./router";
import{ init } from 'emailjs-com';

init("user_idHMr4NsQvaSv51eaZMij");

const Footer = () => import("./components/Footer/Footer.vue");
Vue.component('global-footer', Footer);
const Header = () => import("./components/Header/Header.vue");
Vue.component('global-header', Header);

Vue.config.productionTip = false;

const accessToken = ""; // Leave empty if your repo is public
const endpoint = "https://theticktockdoc.cdn.prismic.io/api/v2"; // Use your repo name

Vue.use(PrismicVue, {
  endpoint,
  apiOptions: { accessToken },
});

new Vue({
  router,
  render: function (h) {
    return h(App);
  },
}).$mount("#app");
