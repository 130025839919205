import Homepage from "./pages/Homepage/Homepage.vue";

export default {
  name: "App",
  components: {
    Homepage,
  },
  data() {
    return {
      loading: true
    };
  },
  mounted() {
    this.$root.$on("loading", () => {
      this.loading = true;
    });

    this.$root.$on("loaded", () => {
      this.loading = false;
    });
  }
};
